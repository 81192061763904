import React from 'react';
import { Link } from 'gatsby';
import { ClickArrow } from './Icon';
import {
  rateMyPG,
  taaskify,
} from '../components/ProjectData';

export default function RecentProjects() {
  return (
    <>
      <section>
        <div className="text-left mt-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-5   dark:text-darkBackgroundYellow text-black">
            Recent Projects
          </h2>
        </div>
        <div className="hover:shadow-lg focus:shadow-lg  mb-5 flex flex-col border border-gray-400 hover:border-gray-600  dark:border-gray-600 dark:hover:border-gray-400  rounded-lg md:mt-5">
          <a
            href={taaskify?.site}
            target="_blank"
            rel="noopener noreferrer"
            className="focus:bg-gray-100 dark:focus:bg-gray-900 p-4 flex"
          >
            <div>
              <h3 className="mb-2 text-xl font-semibold tracking-normal  dark:text-gray-100 ">
                {taaskify?.title}
              </h3>
              <p className="text-sm md:text-base font-normal text-gray-900  dark:text-gray-300">
                {taaskify?.description}
              </p>
              <ul className="flex md:justify-end text-xs md:text-sm mt-3  dark:text-darkVimColor font-semibold">

                {taaskify?.techStack?.map((item) => <li
                  key={item}
                  className="md:ml-1 mr-3">
                  <i>{item}</i>
                </li>)}
              </ul>
            </div>
          </a>
        </div>
        <div className="hover:shadow-lg focus:shadow-lg  flex flex-col border border-gray-400 hover:border-gray-600 dark:border-gray-600 dark:hover:border-gray-400  rounded-lg md:mt-5">
          <a
            href={rateMyPG?.site}
            target="_blank"
            rel="noopener noreferrer"
            className="focus:bg-gray-100 dark:focus:bg-gray-900 p-4 flex"
          >
            <div>
              <h3 className="mb-2 text-xl font-semibold tracking-normal  dark:text-gray-100 ">
                {rateMyPG?.title}
              </h3>
              <p className="text-sm md:text-base font-normal text-gray-900  dark:text-gray-300">
                {rateMyPG?.description}
              </p>
              <ul className="flex md:justify-end text-xs md:text-sm mt-3  dark:text-darkVimColor font-semibold">
                {rateMyPG?.techStack?.map((item) => <li
                  key={item}
                  className="md:ml-1 mr-3">
                  <i>{item}</i>
                </li>)}
              </ul>
            </div>
          </a>
        </div>
        <Link to="/projects">
          <div className=" flex justify-center p-3 focus:text-gray-800  dark:text-gray-300 dark:focus:text-white">
            <div className="dark:hover:text-darkBackgroundYellow hover:text-lightPurple text-center text-sm  md:text-base cursor-pointer">
              More Projects
            </div>
            <ClickArrow />
          </div>
        </Link>
      </section>
    </>
  );
}
