const rateMyPG = {
  title: 'RateMyPG',
  description:
    'This platform lets users search and create personalized PG listings with descriptions and images. Users can rate and review existing PGs based on questionnaires, giving a comprehensive perspective. Facilities and keywords are included. Like/dislike and CRUD operations are being added to improve user functionality and user-friendliness.',
  shortdescription:
    'This platform lets users search and create personalized PG listings with descriptions and images.',
  stack: {
    first: 'NextJS',
    second: 'Tailwind',
    third: 'Firebase',
  },
  imageDark: 'rate-my-pg.png',
  imageLight: 'rate-my-pg.png',
  techStack: ['NextJS', 'Tailwind', 'Firebase'],
  gitHub: 'https://github.com/pranavkhapra/ratemy-pg',
  site: 'https://ratemypg.vercel.app/',
};

const taaskify = {
  title: 'Taaskify',
  description:
    'A web app with a user-friendly dashboard for managing projects and tasks. Users can create a free account and easily access their projects and track overdue tasks. The dashboard shows active projects and tasks, helping users meet deadlines. New features like notifications, kanban boards, and detailed project/task info are being added to improve the user experience.',
  shortdescription:
    'A web app with a user-friendly dashboard for managing projects and tasks',
  stack: {
    first: 'NextJS',
    second: 'Typescript',
    third: 'Prisma',
    fourth: 'Tailwind',
  },
  imageDark: 'taaskify.png',
  imageLight: 'taaskify.png',
  techStack: ['NextJS', 'Typescript', 'Prisma', 'Tailwind'],
  gitHub: 'https://github.com/pranavkhapra/taskify',
  site: 'https://taaskify.vercel.app/login',
};

const weebNative = {
  title: 'Weeb-Native',
  description:
    'This anime information app that i made in order to learn react-native, and includes features like seasonal anime viewing, upcoming anime slider, and search functionality. Users can view anime details and trailers, although demo videos are used instead of YouTube. The app showcases my skills in using different technologies to create an engaging and functional user experience.    ',
  shortdescription:
    'An anime information app that i made in order to learn react-native and the other parts about it.',
  stack: {
    first: 'React-Native',
    second: 'Tailwind',
  },
  imageDark: 'weeb_native.jpg',
  imageLight: 'weeb_native.jpg',
  techStack: ['React-Native', 'Tailwind'],
  gitHub: 'https://github.com/pranavkhapra/weeb-native',
  site: 'https://github.com/pranavkhapra/weeb-native/releases/tag/v1.0.2',
};
const sickSlice = {
  title: 'Sick Slice',
  description:
    'Sick slice is online pizza store that includes all the features of a real world food delivery website.Where you can view pizza on based on toppings with dynamic pages for each pizza ,order them which is send to the shop for delivering.',
  shortdescription:
    'Sick slice a full stack pizza store based on food delivery apps.',
  stack: {
    first: 'Gatsby',
    second: 'Graphql',
    third: 'Sanity',
    fourth: 'Styled-components',
  },
  imageDark: 'sick-slices.png',
  imageLight: 'sick-slices.png',
  techStack: ['Gatsby', 'Graphql', 'Sanity', 'Styled-components'],
  gitHub: 'https://github.com/pranavkhapra/sick-slice',
  site: 'https://sick-slice-pranav-khapra.netlify.app/',
};

const covid19Tracker = {
  title: 'COVID-19 Tracker',
  description:
    'Created a Covid tracker that tracks the covid situation and show it with charts,graphs.A lot of new things this project helped me to learn.',
  shortdescription:
    'Created a Covid tracker that tracks the covid situation and show it with charts,graphs.',

  stack: {
    first: 'React',
    second: 'Chart.js',
    third: 'COVID-19 API',
    fourth: 'Material-UI',
  },
  imageDark: 'covid_tracker_dark.png',
  imageLight: 'covid_tracker_light.png',
  techStack: ['React', 'Chart.js', 'COVID-19 API', 'Material-UI'],
  gitHub: 'https://github.com/pranavkhapra/covid_tracker',
  site: 'https://covid-tracker-pranav-khapra.netlify.app/ ',
};
const catchOfTheDay = {
  title: 'Catch-of-the-Day',
  description:
    'This single-page application mocks up a fish market online store, letting you change descriptions, load samples, add to your cart and more. It is all done using a Firebase real-time database so anyone can make and share stores with each other!',
  shortdescription:
    'Catch of the Day — a real-time seafood market where price and quantity available are variable and can change at a moments notice.',
  stack: {
    first: 'React',
    second: 'Firebase',
    third: 'Stylus',
  },
  imageDark: 'catchoftheday.png',
  imageLight: 'catchoftheday.png',
  techStack: ['React', 'Firebase', 'Stylus'],
  gitHub: 'https://github.com/pranavkhapra/fish-store',
  site: 'https://fish-store-pranav-khapra.netlify.app/',
};
const tikTok = {
  title: 'Tik Tok Clone',
  description: 'A tik-tok type app created for trying the datastax by astra',
  shortdescription:
    'A tik-tok type app created for trying the datastax by astra',
  stack: {
    first: 'React',
    second: 'Stargate',
    third: 'DataStax',
    fourth: 'Astra',
  },
  imageDark: 'tiktok.png',
  imageLight: 'tiktok.png',
  techStack: ['React', 'Stargate', 'DataStax', 'Astra'],
  gitHub: 'https://github.com/pranavkhapra/tik-tok',
  site: 'https://tik-tok-pranav-khapra.netlify.app/',
};
const tweet = {
  title: 'Twitter',
  description:
    'Basically a twitter clone with real time tweet fetching and all attachment the storage authentication and real time is done with Firebase',
  shortdescription:
    'Basically a twitter clone with real time tweet fetching and all attachment the storage authentication and real time is done with Firebase',
  stack: {
    first: 'React',
    second: 'FireBase',
  },
  imageDark: 'twiitter.png',
  imageLight: 'twiitter.png',
  techStack: ['React', 'Stargate', 'DataStax', 'Astra'],
  gitHub: 'https://github.com/pranavkhapra/tweet',
  site: 'https://pranavkhapra.github.io/tweet/#/',
};
const minimialV0 = {
  title: 'Minimalv0',
  description:
    'People love big websites and big portfolio but it is also a place where you can get lost so this it the minimal portfolio.',
  shortdescription:
    'People love big websites and big portfolio but it is also a place where you can get lost so this it the minimal portfolio.',

  stack: {
    first: 'Html',
    second: 'Bootstrap',
  },
  imageDark: 'minimalv0.png',
  imageLight: 'minimalv0.png',
  techStack: ['Html', 'BootStrap'],
  gitHub: 'https://github.com/pranavkhapra/minimal',
  site: 'https://minimal-pranavkhapra.vercel.app/',
};
const uzumakiStore = {
  title: 'Uzumaki Store',
  description:
    'Uzumaki Store just browse around the multitude of clothes until you find something you fancy. After that, you should probably make an account so you can actually buy something.With an account set up, you will have access to the bulk of the application, including the ability to sell your own products and manage them, view your profile, add items to your cart, checkout purchase those items, and review past orders as well.',
  shortdescription:
    'Uzumaki Store, a full stack online clothing store. Users can search, sell, add to cart and checkout their favourite items.',

  stack: {
    first: 'React',
    second: 'Keystone',
    third: 'Graphql',
    fourth: 'Apollo',
    fivth: 'Next.js',
    // sixth: 'TypeScript',
  },
  imageDark: 'uzumaki.png',
  imageLight: 'uzumaki.png',
  techStack: [
    'React',
    'Keystone',
    'Graphql',
    'Apollo Client',
    // 'TypeScript',
    'Next.js',
  ],
  gitHub: 'https://github.com/pranavkhapra/uzumaki-store',
  site: 'https://uzumaki-store.vercel.app/',
};
const youtubeApi = {
  title: 'Youtube Api',
  description:
    'Basically youtube without all the algos .It fetches the data videos and all from youtube-api.Videos are played and are real time also 5 videos per search in recommendation bar',
  shortdescription:
    'Basically youtube without all the algos.It fetches the data videos and all from youtube-api.',

  stack: {
    first: 'React',
    second: 'youtube-api',
  },
  imageDark: 'youtube.png',
  imageLight: 'youtube.png',
  techStack: ['React', 'youtube-api'],
  gitHub: 'https://github.com/pranavkhapra/yt-browser',
  site: 'https://yt-browser-pranav.netlify.app/',
};

const netflixApi = {
  title: 'Netflix Api Store',
  description:
    'Basically a Netflix Web Page That Runs trailer instead of movies. Data fetched from netflix api and yt.',
  shortdescription:
    'Basically a Netflix Web Page That Runs trailer instead of movies. Data fetched from netflix api and yt.',

  stack: {
    first: 'React',
    second: 'Firebase',
  },
  imageDark: 'netflix.png',
  imageLight: 'netflix.png',
  techStack: ['React', 'Firebase'],
  gitHub: 'https://github.com/pranavkhapra/react-NETFLIX-api',
  site: 'https://react-netflix-api-pranav.netlify.app/',
};
const markDownViewer = {
  title: 'Markdown Rea-wer',
  description:
    'Its a pun get it! Its a good pun I came up with this while trying to sleep and coded in a day. I wanted to make a markdown previewer.',
  stack: {
    first: 'React',
    second: 'react-markdown',
  },
  imageDark: 'markdown-rea-wer.png',
  imageLight: 'markdown-rea-wer.png',
  techStack: ['React', 'react-markdown'],
  gitHub: 'https://github.com/pranavkhapra/markdown-rea-wer',
  site: 'https://markdown-online-editor.netlify.app/',
};
const konohaGram = {
  title: 'konaha-gram',
  description:
    'konaha-gram a single page application made, in attempt to revise the integration of Redux with React.You can like posts, navigate the app, and add comments and your changes will be reflected instantly. If you click on the logo, youll be taken back to the base page, where your changes will still be saved, until page reload!',
  stack: {
    first: 'React',
    second: 'redux',
  },
  imageDark: 'konoha-gram.png',
  imageLight: 'konoha-gram.png',
  techStack: ['React', 'redux'],
  gitHub: 'https://github.com/pranavkhapra/konoha-gram',
  site: 'https://konaha-gram.netlify.app/',
};
export {
  sickSlice,
  covid19Tracker,
  catchOfTheDay,
  tikTok,
  tweet,
  uzumakiStore,
  netflixApi,
  youtubeApi,
  minimialV0,
  markDownViewer,
  konohaGram,
  rateMyPG,
  taaskify,
  weebNative,
};
